<template>
  <v-app id="app">
    <!-- <TimingComponent /> -->
    <HalloweenComponent @open="showDialog = true" />
    <FortuneWheelDialog :showDialog="showDialog" @close="showDialog = false" />
    <!-- <AppFooter></AppFooter> -->
  </v-app>
</template>

<script>
// import TimingComponent from "./components/TimingComponent.vue";
import FortuneWheelDialog from "./components/FortuneWheelDialog.vue";
import HalloweenComponent from "./components/HalloweenComponent.vue";
// import AppFooter from "./components/AppFooter.vue";

export default {
  name: "App",
  components: {
    // TimingComponent,
    FortuneWheelDialog,
    HalloweenComponent,
    // AppFooter,
  },
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>

<style>
/* html,
body {
  padding: 0;
  margin: 0;
} */

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ff7300;
 
  height: 100vh;
} */
</style>
