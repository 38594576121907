<template>
  <div id="wheelOfFortune">
    <canvas
      ref="wheel"
      id="wheel"
      :width="wheelDiameter"
      :height="wheelDiameter"
    ></canvas>
    <div ref="spin" id="spin">Ganhe Prémios</div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, defineProps, defineEmits } from "vue";

const props = defineProps({
  spinFlag: {
    type: Number,
  },
});

const emit = defineEmits(["newPrize"]);

const sectors = [
  {
    prize: "gift",
    color: "#f07c04",
    label: "Brinde",
    image: "images/gift.svg",
  },
  {
    prize: "off15",
    color: "#45547F",
    label: "15% Desconto",
    image: "images/rosette-discount.svg",
  },
  {
    prize: "off25",
    color: "#815593",
    label: "25% Desconto",
    image: "images/rosette-discount.svg",
  },
  {
    prize: "freeShipping",
    color: "#EE5358",
    label: "Portes Grátis",
    image: "images/truck.svg",
  },
  {
    prize: "eur20",
    color: "#C44C85",
    label: "Vale 20€",
    image: "images/cash.svg",
  },
  {
    prize: "eur50",
    color: "#2F4858",
    label: "Vale 50€",
    image: "images/cash.svg",
  },
  {
    prize: "noPrize",
    color: "#000",
    label: "Sem prémio",
    image: "images/mood-sad.svg",
  },
];

const spin = ref(null);
const wheel = ref(null);

const wheelDiameter = 300;

onMounted(() => {
  const rand = (m, M) => Math.random() * (M - m) + m;
  const tot = sectors.length;
  const spinEl = spin.value;
  const ctx = wheel.value.getContext("2d");
  const dia = ctx.canvas.width;
  const rad = dia / 2;
  const PI = Math.PI;
  const TAU = 2 * PI;
  const arc = TAU / sectors.length;
  const lastLabel = ref("Ganhe Prémios");

  const friction = 0.991; // 0.995=soft, 0.99=mid, 0.98=hard
  let angVel = 0; // Angular velocity
  let ang = 0; // Angle in radians

  const getIndex = () => Math.floor(tot - (ang / TAU) * tot) % tot;

  function drawSector(sector, i, image) {
    const ang = arc * i;
    ctx.save();
    // COLOR
    ctx.beginPath();
    ctx.fillStyle = sector.color;

    ctx.moveTo(rad, rad);
    ctx.arc(rad, rad, rad, ang, ang + arc);
    ctx.lineTo(rad, rad);
    ctx.fill();
    // TEXT
    ctx.translate(rad, rad);
    ctx.rotate(ang + arc / 2);
    ctx.textAlign = "right";
    ctx.fillStyle = "#fff";
    //cursor default

    ctx.drawImage(image, 80, -25, 50, 50);

    ctx.restore();
  }

  function rotate() {
    const sector = sectors[getIndex()];
    ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`;
    spinEl.textContent = !angVel
      ? lastLabel.value
      : ((lastLabel.value = sector.label), sector.label);
    spinEl.style.background = sector.color;
  }

  function frame() {
    if (!angVel) return;
    angVel *= friction; // Decrement velocity by friction
    if (angVel < 0.002) {
      angVel = 0; // Bring to stop
      let newPrize = sectors[getIndex()].prize;
      emit("newPrize", newPrize);
    }
    ang += angVel; // Update angle
    ang %= TAU; // Normalize angle
    rotate();
  }

  function engine() {
    frame();
    requestAnimationFrame(engine);
  }

  function init() {
    sectors.forEach((sector, i) => {
      let img = new Image();
      img.src = sector.image;

      img.onload = function () {
        drawSector(sector, i, img);
      };
    });

    rotate(); // Initial rotation
    engine(); // Start engine
    spinEl.style.cursor = "default";
  }

  function spinWheel() {
    if (!angVel) {
      angVel = rand(0.25, 0.45);

      spinEl.style.cursor = "default";
    }
  }

  watch(
    () => props.spinFlag,
    () => {
      spinWheel();
    }
  );

  init();
});
</script>

<style scoped>
#wheelOfFortune {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border: 8px solid #fff;
  border-radius: 50%;
  /* filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.76)); */
}

#wheel {
  display: block;
}

#spin {
  text-align: center;
  font: 18px sans-serif;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 30%;
  margin: -15%;
  background: #fff;
  color: #fff;
  box-shadow: 0 0 0 8px currentColor, 0 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  transition: 0.8s;
}

#spin::after {
  content: "";
  position: absolute;
  top: -17px;
  border: 10px solid transparent;
  border-bottom-color: currentColor;
  border-top: none;
}
</style>
