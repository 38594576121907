<template>
  <v-dialog
    :model-value="props.showDialog"
    transition="dialog-top-transition"
    style="margin: 0px; padding: 0px"
    fullscreen
  >
    <v-card
      style="
        width: min(900px, 100vw) !important;
        min-width: min(900px, 100vw) !important;
      "
      color="#ff7300"
    >
      <v-toolbar color="#ff7300" title=" ">
        <v-btn
          style="z-index: 9999"
          icon
          variant="plain"
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img width="120" height="auto" class="logo" src="images/logo-white.png">
      </v-img>

      <v-container class="fortuneContainer">
        <v-row class="align-center">
          <v-col md="6" class="d-flex align-center">
            <FortuneWheel
              @newPrize="savePrize"
              :spin-flag="spinWheel"
              style="margin-inline: auto; z-index: 9999"
            />
          </v-col>

          <v-col md="6" class="px-4" style="z-index: 9999">
            <v-card-text v-if="!voucher">
              <h1 class="mb-2">Rode e ganhe prémios</h1>

              <p>Tente a sua sorte e ganhe um prémio.</p>
              <p>Um prémio por email.</p>
              <p>Prémios limitados.</p>
              <br />

              <v-form
                v-model="valid"
                :disabled="loading"
                @submit.prevent="submit"
                style="z-index: 9999"
              >
                <v-text-field
                  v-model="name"
                  class="mb-1"
                  :rules="rules"
                  label="Nome"
                  variant="solo"
                  bg-color="white"
                  density="compact"
                ></v-text-field>

                <v-text-field
                  v-model="email"
                  class="mb-1"
                  :rules="emailRules"
                  label="Endereço de Email"
                  variant="solo"
                  bg-color="white"
                  density="compact"
                  type="email"
                ></v-text-field>

                <v-text-field
                  v-model="phone"
                  class="mb-1"
                  label="Contacto Telefónico"
                  variant="outlined"
                  bg-color="white"
                  density="compact"
                ></v-text-field>

                <span
                  >Ao participar está a concordar com os
                  <a href="termos.pdf" target="__blank"
                    >Termos e Condições do concurso</a
                  >.</span
                >
                <v-btn
                  color="yellow"
                  :loading="loading"
                  class="mt-2"
                  type="submit"
                  block
                  size="large"
                  >Tente a sua sorte</v-btn
                >
              </v-form>

              <v-slide-y-transition>
                <p v-if="submitErrorMessage.length" class="error-messages">
                  {{ submitErrorMessage }}
                </p>
              </v-slide-y-transition>
            </v-card-text>

            <v-card-text v-else>
              <h1>{{ getPrizeName(prize) }}</h1>
              <p v-if="prize != 'noPrize'" class="my-2">
                Guarde o Voucher para usar no lançamento da nossa loja online.
              </p>

              <VoucherForm
                v-if="prize != 'noPrize'"
                :voucher-code="voucher"
                :prize="getPrizeNameShort(prize)"
              ></VoucherForm>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
      <ConfettiAnimation></ConfettiAnimation>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps } from "vue";
import axios from "axios";
import FortuneWheel from "./FortuneWheel.vue";
import ConfettiAnimation from "./ConfettiAnimation.vue";
import VoucherForm from "./VoucherForm.vue";

const spinWheel = ref(0);
const loading = ref(false);
const name = ref("");
const email = ref("");
const phone = ref("");
const voucher = ref("");
const prize = ref("");
const valid = ref();
const submitErrorMessage = ref("");

const props = defineProps(["showDialog"]);

const rules = ref([
  (value) => {
    if (value) return true;

    return "Preencha este campo.";
  },
]);

const emailRules = ref([
  (value) => {
    if (value) return true;

    return "Preencha este campo.";
  },
  (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) return true;

    return "Insira um email válido.";
  },
]);

function getPrizeName(prize) {
  switch (prize) {
    case "gift":
      return "Parabéns! Ganhou um Brinde";
    case "off15":
      return "Parabéns! Ganhou 15% de Desconto";
    case "off25":
      return "Parabéns! Ganhou 25% de Desconto";
    case "freeShipping":
      return "Parabéns! Ganhou Portes Grátis";
    case "eur20":
      return "Parabéns! Ganhou um Vale de 20€";
    case "eur50":
      return "Parabéns! Ganhou um Vale de 50€";
    case "noPrize":
      return "Não foi desta vez! Mas os prémios ainda agora estão a começar.";
  }
}

function getPrizeNameShort(prize) {
  switch (prize) {
    case "gift":
      return "Brinde";
    case "off15":
      return "15% de Desconto";
    case "off25":
      return "25% de Desconto";
    case "freeShipping":
      return "Portes Grátis";
    case "eur20":
      return "Vale de 20€";
    case "eur50":
      return "Vale de 50€";
    case "noPrize":
      return "Não foi desta vez! Mas os prémios ainda agora estão a começar.";
  }
}

function submit() {
  //validate form
  if (!valid.value) return;

  submitErrorMessage.value = "";
  spinWheel.value++;
  loading.value = true;
}

function savePrize(newPrize) {
  loading.value = false;
  prize.value = newPrize;

  axios
    .post("https://domus-server.oficinasofton.pt/api/participate", {
      email: email.value,
      name: name.value,
      phone: phone.value,
      prize: newPrize,
    })
    .then(({ data }) => {
      voucher.value = data.voucherCode;
    })
    .catch((error) => {
      console.error(error);

      if (
        error?.response?.data?.error?.status == 400 &&
        error?.response?.data?.error?.details?.errors[0]?.path[0] == "email"
      ) {
        submitErrorMessage.value =
          "Email inserido já foi registado ou é inválido!";
      } else {
        submitErrorMessage.value =
          "Não é possível participar de momento, tente mais tarde!";
      }
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<style lang="scss" scoped>
.fortuneContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

a {
  color: white;
}

.logo {
  position: absolute;
  top: 16px;
  z-index: 9999;
  left: 16px;
}

.error-messages {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-top: 8px;
}
</style>
