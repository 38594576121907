<template>
  <div class="halloweenBackground">
    <v-container>
      <v-row align="center" class="halloweenContainer">
        <v-col class="text-right halloweenCol align-center" cols="12" md="8">
          <div class="logoDiv">
            <v-img class="logoImg" src="/images/logo-white.png" width="300" />
            <span class="logoText">O melhor de tudo num só lugar</span>
          </div>

          <p class="halloweenText">
            OBRIGADO a todos os que nos acarinham e têm expetativas elevadas
            para a DOMUSMELODY.
          </p>

          <p class="halloweenText">
            O vosso apoio e interesse têm-nos forçado a apostar tudo na
            “perfeição”, na melhor resposta para as vossas expetativas - e por
            isso, estando a trabalhar incansavelmente com esse único propósito,
            estamos a demorar um pouco mais do que o previsto. Mas vão-nos
            acompanhando, as novidades sobre a abertura de portas vão chegar a
            qualquer momento. :)
          </p>

          <p class="my-6">
            <a
              href="https://www.facebook.com/profile.php?id=61565041173795"
              target="_blank"
            >
              <v-icon color="white" size="30" class="mx-2">mdi-facebook</v-icon>
            </a>

            <a href="https://www.instagram.com/domusmelody.pt/" target="_blank">
              <v-icon color="white" size="30" class="mx-2"
                >mdi-instagram</v-icon
              >
            </a>
          </p>

          <v-btn
            class="mt-4 halloweenBtn"
            @click="$emit('open')"
            color="#FF7518"
            >Ganhe Prémios</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.halloweenContainer {
  // display: flex;
  // flex-direction: column;
  // justify-content: center; /* Centers content vertically */
  // align-items: center; /* Centers content horizontally */
  height: 100vh; /* Full viewport height */
  justify-content: flex-end; /* Aligns the column to the right */
  align-items: center; /* Centers the content vertically */
}

.halloweenBackground {
  background-image: url("@/assets/halloween.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.halloweenText {
  font-size: 16px;
  color: white;
  margin-top: 20px;
}

.halloweenBtn {
  filter: drop-shadow(4px 4px 40px #ff7518);
}

.logoText {
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  text-transform: uppercase;
}

.logoDiv {
  align-content: right;
  text-align: right;
  margin-bottom: 100px;
}

.logoImg {
  display: block;
  margin: 0 0 0 auto; /* Aligns the image to the right */
}

.halloweenCol {
  margin-left: auto;
}
</style>
